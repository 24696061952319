.data-sub {
	padding: 10px;
	background-color: rgb(255,255,255);
}

.data-sub-img img {
	max-width: 100%;
}

.btn-sub {
	font-size: 14px;
	background-color: rgb();
	padding: 5px;
}


.button7 {
  font-weight: 700;
  color: white;
  text-decoration: none;
  padding: .8em 1em calc(.8em + 3px);
  border-radius: 3px;
  background: rgb(64,199,129);
  box-shadow: 0 -3px rgb(53,167,110) inset;
  transition: 0.2s;
  display: inline-block;
} 
.button7:hover { background: rgb(53, 167, 110); }
.button7:active {
  background: rgb(33,147,90);
  box-shadow: 0 3px rgb(33,147,90) inset;
}

.data-sub p {
	line-height: 20px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}